import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import {
  selectMessagesByThreadId,
  selectIsFetchingHistory,
  selectIsLoadingMessage,
} from '../../../store/chat/chat.slice';
import { Steps } from '../../../store/onboarding/onboardingSlice';
import { selectActiveThread, selectIsThreadLoading } from '../../../store/thread/threadSlice';
import ChatInput from '../../molecule/ChatInput/ChatInput';
import SafetyNotice from '../../organism/SafetyNotice/SafetyNotice';
import ServicesSection from '../../organism/ServicesSection/ServicesSection';
import SpeechBubble from '../../organism/SpeechBubble/SpeechBubble';
import WelcomeSection from '../../molecule/WelcomeSection/WelcomeSection';
import { useAppSelector } from '../../../configuration/setup/hooks';

const SAFETY_NOTICE_KEY = 'termsAcceptedAt';
const DAYS_90 = 90 * 24 * 60 * 60 * 1000;

type ChatWindowProps = {
  handleSend: (content: string, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
  isLoading?: boolean;
};

const ChatWindow = ({ handleSend }: ChatWindowProps) => {
  const intl = useIntl();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const activeThread = useAppSelector(selectActiveThread);
  const localId = activeThread ? activeThread.id : null;
  const messages = useAppSelector((state) =>
    activeThread ? selectMessagesByThreadId(activeThread?.threadId)(state) : undefined,
  );

  const isThreadsLoading = useAppSelector(selectIsThreadLoading(localId));
  const isHistoryFetching = useAppSelector(selectIsFetchingHistory);
  const isLoading = useAppSelector(selectIsLoadingMessage);

  const [showSafetyNotice, setShowSafetyNotice] = useState(false);
  const [isSafetyNoticeChecked, setIsSafetyNoticeChecked] = useState(localStorage.getItem(SAFETY_NOTICE_KEY));

  const startDate = parseInt(localStorage.getItem(SAFETY_NOTICE_KEY) ?? '0', 10);
  const hiddenUntil = isNaN(startDate) ? 0 : new Date(startDate).getTime();
  const now = Date.now();

  const handleSendFromInput = (message: string) => handleSend(message);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSetShow = (value: boolean) => setShowSafetyNotice(value);

  useEffect(() => {
    if (now - hiddenUntil > DAYS_90) {
      setShowSafetyNotice(true);
    }
  }, []);

  const handleCheckSafetyNoticeDate = () => setIsSafetyNoticeChecked((prev) => !prev);

  useEffect(() => {
    if (isSafetyNoticeChecked) {
      localStorage.setItem(SAFETY_NOTICE_KEY, Date.now().toString());
    }
  }, [isSafetyNoticeChecked]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  return (
    <ChatWindowWrapper>
      {showSafetyNotice ? (
        <SafetyNotice
          show={showSafetyNotice}
          handleOnChange={handleCheckSafetyNoticeDate}
          checked={isSafetyNoticeChecked}
          setShow={handleSetShow}
          onClose={() => setShowSafetyNotice(false)}
        />
      ) : null}
      <ContentWrapper>
        {Array.isArray(messages) && messages.length === 0 && messages.length === 0 && isHistoryFetching ? (
          <Spinner />
        ) : null}
        {Array.isArray(messages) &&
        messages.length === 0 &&
        !isThreadsLoading &&
        !isHistoryFetching &&
        activeThread?.threadId !== null ? (
          <div className="alert alert-dismissible alert-primary width-50pct">
            <div className="display-flex gap-10">
              <span className="text-color-primary text-size-h4 rioglyph rioglyph-info-sign" />
              <div>
                <strong className="text-size-16">
                  {intl.formatMessage({ id: 'chatPage.chatThreadList.error.noMessage.title' })}
                </strong>
                <div className="margin-y-5">
                  {intl.formatMessage({ id: 'chatPage.chatThreadList.error.noMessage' })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {(!Array.isArray(messages) || activeThread?.threadId === null) && !isThreadsLoading && !isHistoryFetching ? (
          <Fragment>
            <WelcomeSection />
            <ServicesSection onClickQuestion={(message, isOnboarding) => handleSend(message, isOnboarding, 'step-1')} />
          </Fragment>
        ) : null}
        <MessagesSection>
          {Array.isArray(messages) &&
            messages.map((msg, index) => (
              <SpeechBubble key={`speechBubble-${index}`} messageId={msg.id} handleSend={handleSend} />
            ))}
          {isThreadsLoading && isLoading ? (
            <div className=" min-height-200">
              <Spinner text={intl.formatMessage({ id: 'chatPage.processIndicator.label' })} />
            </div>
          ) : null}
          <div ref={messagesEndRef} style={{ marginBottom: '-0.5rem' }} />
        </MessagesSection>
      </ContentWrapper>
      <ChatInput isLoading={isThreadsLoading} onSendMessage={(message) => handleSendFromInput(message)} />
    </ChatWindowWrapper>
  );
};

export default ChatWindow;

const ChatWindowWrapper = styled.div`
  padding: 2rem;
  background: var(--color-white);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1rem);
  padding-right: 2rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--color-black), 0.4);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(var(--color-black), 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  scrollbar-width: thin;
  scrollbar-color: rgba(var(--color-black), 0.4) rgba(var(--color-black), 0.1);
`;

const MessagesSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
