import { Fragment, useEffect, useRef } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ChatAssetTree from '../../atom/ChatAssetTree/ChatAssetTree';
import { resetToOnboarding, selectCurrentLayout } from '../../../store/layout/layoutSlice';
import AboutNavigation from '../../atom/AboutNavigation/AboutNavigation';
import ExampleQuestionsList from '../../molecule/ExampleQuestionsList/ExampleQuestionsList';
import ChatHistoryList from '../../organism/ChatHistoryList/ChatHistoryList';
import FavoritesThreadsList from '../../organism/FavoritesThreadsList/FavoritesThreadsList';
import ChatWindow from '../ChatWindow/ChatWindow';
import GetStartedPage from '../GetStarted/GetStartedPage';
import FeedbackPage from '../FeedbackPage/FeedbackPage';
import FeedbackInfoSidebar from '../../organism/FeedbackInfoSidebar/FeedbackInfoSidebar';
import OnboardingIntroductionContent from '../../organism/OnboardingIntroductionContent/OnboardingIntroductionContent';
import {
  activateOnboardingMode,
  handleNextOnboardingStep,
  selectIsOnboardingMode,
  selectOnboardingStep,
  Steps,
} from '../../../store/onboarding/onboardingSlice';
import { getLocale } from '../../../configuration/lang/langSlice';
import {
  addNewThread,
  selectActiveThread,
  setActiveThread,
  updateThreadTitle,
} from '../../../store/thread/threadSlice';
import { addMessage, setLoadingMessage } from '../../../store/chat/chat.slice';
import { getUserId } from '../../../utils/getUserId';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { useSendMessageMutation } from '../../../services/chatApi/chatApi';
import { useIntl } from 'react-intl';
import OnboardingWalkthrough from '../../organism/OnboardingWalkthrough/OnboardingWalkthrough';
import { openSidebar } from '../../../store/sidebar/sidebarSlice';
import MfaNotification from '../../molecule/MfaNotification/MfaNotification';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { nanoid } from '@reduxjs/toolkit';
import { reduxStore } from '../../../store/store';

export const componentMap = {
  chatHistory: {
    sidebarComponent: ChatHistoryList,
    mainComponent: ChatWindow,
  },
  favorites: {
    sidebarComponent: FavoritesThreadsList,
    mainComponent: ChatWindow,
  },
  faq: {
    sidebarComponent: ExampleQuestionsList,
    mainComponent: ChatWindow,
  },
  about: {
    sidebarComponent: AboutNavigation,
    mainComponent: GetStartedPage,
  },
  feedback: {
    sidebarComponent: FeedbackInfoSidebar,
    mainComponent: FeedbackPage,
  },
  onboardingMode: {
    sidebarComponent: OnboardingIntroductionContent,
    mainComponent: ChatWindow,
  },
} as const;

const ChatPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const activePage = useAppSelector(selectCurrentLayout);
  const currentLanguage = useAppSelector(getLocale);
  const activeThread = useAppSelector(selectActiveThread);
  const onboardingStep = useAppSelector(selectOnboardingStep);
  const isOnboardingMode = useAppSelector(selectIsOnboardingMode);
  const user = useAppSelector(getUserProfile);
  const activeThreadIdRef = useRef(activeThread?.threadId);
  const userId = getUserId(user?.sub ?? '');
  const [sendMessage, { isError, isLoading, isSuccess, ...rest }] = useSendMessageMutation();
  const LAST_SEEN_MFA_NOTIFICATION = 'lastSeenMfaNotification';
  const DAYS_30 = 30 * 24 * 60 * 60 * 1000;

  const showMfaNotification = () => {
    Notification.info(<MfaNotification />, intl.formatMessage({ id: 'mfa.notification.title' }), 999999, () => {});
    localStorage.setItem(LAST_SEEN_MFA_NOTIFICATION, Date.now().toString());
  };
  const handleSend = async (content: string, isOnboarding: boolean = false, nextStepIndex?: Steps) => {
    try {
      const currentActiveThread = selectActiveThread(reduxStore.getState());

      if (isOnboarding) {
        dispatch(handleNextOnboardingStep());
      }

      if (!currentActiveThread?.id || currentActiveThread?.id === null) {
        dispatch(addNewThread());
      }

      if (isOnboarding && currentActiveThread) {
        dispatch(setActiveThread(currentActiveThread.id));
      }

      dispatch(updateThreadTitle({ title: content }));

      const threadId = currentActiveThread?.threadId ?? null;

      const requestedMessage = {
        question: content,
        language: currentLanguage === 'de-DE' ? 'Deutsch' : 'English',
        threadId,
        meta: { isOnboarding, currentLanguage, onboardingStep, nextStepIndex, localId: currentActiveThread?.id },
        userId,
      };

      dispatch(
        addMessage({
          content,
          role: 'human',
          threadId,
          localThreadId: currentActiveThread?.id ?? nanoid(),
          timestamp: new Date().toISOString(),
          isOnboardingQuestion: false,
        }),
      );

      dispatch(setLoadingMessage(true));

      await sendMessage(requestedMessage).unwrap();
    } catch (err) {
      console.error('Failed to send the message: ', err);
    }
  };

  useEffect(() => {
    if (isError) {
      const errorMessage = intl.formatMessage({ id: 'common.error' });
      Notification.error(errorMessage);
      dispatch(
        addMessage({
          content: errorMessage,
          role: 'ai',
          threadId: activeThread?.threadId ?? null,
          localThreadId: activeThread?.id ?? null,
          timestamp: new Date().toISOString(),
          isOnboardingQuestion: false,
        }),
      );
    }
    if (isSuccess) {
      const response = rest?.data;
      if (response?.status === 'failure') {
        const failureMessage = response?.message || intl.formatMessage({ id: 'common.error' });
        dispatch(
          addMessage({
            content: failureMessage,
            role: 'ai',
            threadId: activeThread?.threadId ?? null,
            localThreadId: activeThread?.id ?? null,
            timestamp: new Date().toISOString(),
            isOnboardingQuestion: false,
          }),
        );
      }
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    activeThreadIdRef.current = activeThread?.threadId;
  }, [activeThread?.threadId]);

  useEffect(() => {
    if (isOnboardingMode) {
      dispatch(openSidebar());
    }
  }, [isOnboardingMode, dispatch]);

  useEffect(() => {
    const isFirstLoginOnboarding = localStorage.getItem('isFirstLoginOnboarding') === null;
    if (isFirstLoginOnboarding && activePage === 'chatHistory') {
      dispatch(activateOnboardingMode());
      localStorage.setItem('isFirstLoginOnboarding', 'false');
      dispatch(resetToOnboarding());
    }
  }, [activePage, dispatch]);

  useEffect(() => {
    const startDate = parseInt(localStorage.getItem(LAST_SEEN_MFA_NOTIFICATION) ?? '0', 10);
    const hiddenUntil = isNaN(startDate) ? 0 : new Date(startDate).getTime();
    const now = Date.now();
    if (now - hiddenUntil > DAYS_30) {
      showMfaNotification();
    }
  }, [dispatch]);

  const MainComponent = componentMap[activePage].mainComponent;

  return (
    <Fragment>
      <ChatAssetTree handleSend={handleSend} isLoading={isLoading} />
      <ApplicationLayout.Body>
        <MainComponent handleSend={handleSend} isLoading={isLoading} />
        <OnboardingWalkthrough />
      </ApplicationLayout.Body>
    </Fragment>
  );
};

export default ChatPage;
