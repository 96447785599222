import Tree, { SelectionChangeResponse } from '@rio-cloud/rio-uikit/components/assetTree/Tree';
import TreeCategory from '@rio-cloud/rio-uikit/components/assetTree/TreeCategory';
import { addQuestions, selectAllFAQs } from '../../../store/faq/faqSlice';
import { getMockFAQs } from '../../../../mocks/mock.faqs';
import { useIntl } from 'react-intl';
import getDatesToTranslations from '../../../utils/getDatesToTranslations/getDatesToTranslations';
import { selectActiveThread, selectIsThreadLoading } from '../../../store/thread/threadSlice';
import styled from 'styled-components';
import { Steps } from '../../../store/onboarding/onboardingSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

type ExampleQuestionsListProps = {
  isLoading: boolean;
  handleSend?: (content: string, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
};

const ExampleQuestionsList = ({ isLoading, handleSend }: ExampleQuestionsListProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const activeThread = useAppSelector(selectActiveThread);
  const localId = activeThread ? activeThread.id : null;
  const isThreadsLoading = useAppSelector(selectIsThreadLoading(localId));
  const allFAQs = useAppSelector(selectAllFAQs);
  const dates = getDatesToTranslations();
  const mappedFAQs = getMockFAQs().map((faq) => ({
    id: faq.id,
    name: intl.formatMessage({ id: faq.translation }, dates),
    type: faq.icon,
  }));

  if (allFAQs.length === 0) dispatch(addQuestions(mappedFAQs));

  const handleSelection = async ({ items }: SelectionChangeResponse) => {
    const question = mappedFAQs.find((q) => q.id === items[0]);
    if (isLoading || isThreadsLoading || !question) return;
    if (handleSend) {
      handleSend(question.name);
    }
  };

  return (
    <TreeCategory
      key="faqs-tree-category"
      id="faqs-tree-category"
      label="Question"
      icon="rioglyph rioglyph-question-sign"
      hasSelection={false}
    >
      <StyledTree
        groups={[]}
        items={allFAQs}
        expandedGroups={[]}
        selectedGroups={[]}
        selectedItems={[]}
        searchPlaceholder={intl.formatMessage({ id: 'chatPage.searchList.placeholder' })}
        hasMultiselect={false}
        onSelectionChange={handleSelection}
        $isloading={isLoading || isThreadsLoading}
      />
    </TreeCategory>
  );
};

export default ExampleQuestionsList;

const StyledTree = styled(Tree)<{ $isloading: boolean }>`
  & > .TreeRoot .TreeNodeContainer .TreeNode .TreeLabel.TreeLabelName,
  .TreeRoot .TreeNodeContainer .TreeLeaf .TreeLabel.TreeLabelName {
    cursor: ${(props) => (props.$isloading ? 'not-allowed' : 'pointer')} !important;
  }
`;
