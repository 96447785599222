import styled from 'styled-components';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';

type ServiceCardProps = {
  description: string;
  onClickFunction: (value: string) => void;
};

const OnboardingServiceCard = ({ description, onClickFunction }: ServiceCardProps) => {
  const isDarkMode = useDarkMode();

  return (
    <ServiceCardWrapper
      className="panel panel-primary panel-body panel-blank shadow-default padding-15 margin-bottom-0 position-relative hover-scale-105 transition-all transition-duration-03 transition-ease-in-out"
      onClick={() => onClickFunction(description)}
      $isdarkmode={isDarkMode}
    >
      <span className="position-absolute left--1 top-15 height-25 width-3 bg-primary" />
      <StyledParagraph $isdarkmode={isDarkMode} className="text-color-darker margin-top-4 text-size-12">
        {description}
      </StyledParagraph>
    </ServiceCardWrapper>
  );
};

export default OnboardingServiceCard;

const ServiceCardWrapper = styled.div<{ $isdarkmode: boolean }>`
  cursor: pointer;
  max-width: 170px;
  height: 180px;
  background-color: ${({ $isdarkmode }) => ($isdarkmode ? 'var(--gray-dark)' : 'white')};
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  background: ${({ $isdarkmode }) =>
    $isdarkmode
      ? 'radial-gradient(circle, rgba(48, 180, 192, 0.1), rgba(0, 0, 0, 0.6))'
      : 'radial-gradient(circle, rgba(255, 255, 255, 0.5), rgba(48, 180, 192, 0.2))'};
  animation:
    pulse 1.5s infinite ease-in-out,
    float 3s infinite ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: ${({ $isdarkmode }) =>
      $isdarkmode ? '0 12px 24px rgba(255, 255, 255, 0.3)' : '0 12px 24px rgba(0, 0, 0, 0.3)'};
  }

  @keyframes pulse {
    0% {
      box-shadow: ${({ $isdarkmode }) =>
        $isdarkmode ? '0 0 10px rgba(48, 180, 192, 0.2)' : '0 0 10px rgba(48, 180, 192, 0.4)'};
    }
    50% {
      box-shadow: ${({ $isdarkmode }) =>
        $isdarkmode ? '0 0 25px rgba(48, 180, 192, 0.4)' : '0 0 25px rgba(48, 180, 192, 0.6)'};
    }
    100% {
      box-shadow: ${({ $isdarkmode }) =>
        $isdarkmode ? '0 0 10px rgba(48, 180, 192, 0.2)' : '0 0 10px rgba(48, 180, 192, 0.4)'};
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(6px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const StyledParagraph = styled.div<{ $isdarkmode: boolean }>`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  margin: 0;
  text-align: left;
  font-weight: 600;
  color: ${({ $isdarkmode }) => ($isdarkmode ? 'var(--color-white)' : 'var(--color-black)')};
`;
