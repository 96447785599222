import Button from '@rio-cloud/rio-uikit/Button';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCurrentPage } from '../../../store/about/aboutSlice';
import { setCurrentLayout } from '../../../store/layout/layoutSlice';
import {
  gaPush,
  GET_STARTED_SECTION_ACTIONS,
  GET_STARTED_SECTION_CATEGORY,
} from '../../../configuration/setup/googleAnalytics';

const texts = {
  description: 'getStartedPage.getStartedSection.description',
  learnMoreLabel: 'getStartedPage.getStartedSection.learnMore.label',
  titlePartOne: 'getStartedPage.getStartedSection.title.partOne',
  titlePartTwo: 'getStartedPage.getStartedSection.title.partTwo',
  startChatLabel: 'getStartedPage.common.startChat.label',
};

const GetStartedSection = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isDarkMode = useDarkMode();

  const handleReadMore = () => dispatch(setCurrentPage('page2'));
  const handleStartConversation = () => {
    dispatch(setCurrentLayout('chatHistory'));
    gaPush({
      category: GET_STARTED_SECTION_CATEGORY,
      action: GET_STARTED_SECTION_ACTIONS.CLICK_START_CHAT,
      label: 'User clicked start chat',
    });
  };

  return (
    <Container isDarkMode={isDarkMode}>
      <StyledImage
        src="assets/images/how-it-works.svg"
        alt={`${intl.formatMessage({ id: texts.titlePartOne })} ${intl.formatMessage({ id: texts.titlePartTwo })}`}
      />
      <Content>
        <h3 className="h3 text-uppercase">
          <FormattedMessage id={texts.titlePartOne} />
        </h3>
        <h3 className="h3 text-uppercase text-color-primary">
          <FormattedMessage id={texts.titlePartTwo} />
        </h3>
        <p>
          <FormattedMessage id={texts.description} />
        </p>
        <ButtonContainer>
          <Button bsStyle={Button.PRIMARY} onClick={handleStartConversation}>
            <FormattedMessage id={texts.startChatLabel} />
          </Button>
          <Button bsStyle={Button.DEFAULT} onClick={handleReadMore} className="margin-left-5">
            <FormattedMessage id={texts.learnMoreLabel} />
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default GetStartedSection;

type ContainerProps = {
  isDarkMode: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 6rem 4rem;
  background: ${(props) => (!props.isDarkMode ? 'var(--color-white)' : 'var(--gray-darkest)')};
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isDarkMode ? 'var(--color-white)' : 'var(--gray-darkest)')};

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 1rem;
  }
`;

const StyledImage = styled.img`
  max-width: 40%;

  @media (max-width: 768px) {
    max-width: 80%;
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  max-width: 35%;
  padding-right: 8rem;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 6rem;
  }

  h3 {
    text-transform: uppercase;
    text-align: right;
    margin-top: 10px !important;
    font-weight: bold;
    line-height: 0.8;
  }

  p {
    margin: 2rem 0;
    text-align: right;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  gap: 1rem;
`;
