import { FormattedMessage } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../store/about/aboutSlice';
import PageNavigation from '../PageNavigation/PageNavigation';
import { setCurrentLayout } from '../../../store/layout/layoutSlice';
import ExpanderList from '@rio-cloud/rio-uikit/components/expander/ExpanderList';
import {
  FREQUENTLY_ASKED_QUESTIONS_ACTIONS,
  FREQUENTLY_ASKED_QUESTIONS_CATEGORY,
  gaPush,
} from '../../../configuration/setup/googleAnalytics';

const promptText = {
  introduction: 'getStartedPage.frequentlyAskedQuestions.introduction',
  aiBasicsHeading: 'getStartedPage.frequentlyAskedQuestions.aiBasics.title',
  aiBasicsQuestionsAnswers: [
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionOne',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerOne',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionTwo',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerTwo',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionThree',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerThree',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.aiBasics.questionFour',
      answer: 'getStartedPage.frequentlyAskedQuestions.aiBasics.answerFour',
    },
  ],
  promptingHeading: 'getStartedPage.frequentlyAskedQuestions.prompting.title',
  promptingQuestionsAnswers: [
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionOne',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerOne',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionTwo',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerTwo',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionThree',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerThree',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.prompting.questionFour',
      answer: 'getStartedPage.frequentlyAskedQuestions.prompting.answerFour',
    },
  ],
  dataProtectionAndProcessingHeading: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.title',
  dataProtectionAndProcessingQuestionsAnswers: [
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionOne',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerOne',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionTwo',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerTwo',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionThree',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerThree',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionFour',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerFour',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionFive',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerFive',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionSix',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerSix',
    },
    {
      question: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.questionSeven',
      answer: 'getStartedPage.frequentlyAskedQuestions.dataProtectionAndProcessing.answerSeven',
    },
  ],
};

const classes = {
  container: 'margin-top-25',
  panel: 'panel panel-default panel-body bg-lighter panel-blank position-relative padding-0',
  sectionTitle: 'lead margin-top-25',
  iconList: 'icon-list margin-bottom-0',
  successIcon: 'rioglyph rioglyph-ok-sign text-color-success position-relative bottom-2',
  successIconDo: 'rioglyph rioglyph-ok-sign text-color-success margin-right-10 text-size-18 position-relative top-2',
  dangerIcon: 'rioglyph rioglyph-remove-sign text-color-danger position-relative bottom-3',
  flexColumn: 'display-flex flex-column gap-5',
};

const HowWritePromptsSection = () => {
  const dispatch = useDispatch();
  const pageTopRef = useRef<HTMLDivElement>(null);

  const handlePreviousSection = () => dispatch(setCurrentPage('page3'));
  const handleGoToChat = () => {
    dispatch(setCurrentLayout('chatHistory'));
    gaPush({
      category: FREQUENTLY_ASKED_QUESTIONS_CATEGORY,
      action: FREQUENTLY_ASKED_QUESTIONS_ACTIONS.CLICK_START_CHAT,
      label: 'User clicked start chat',
    });
  };

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const [aiBasicsItems, setAiBasicsItems] = useState(
    promptText.aiBasicsQuestionsAnswers.map((item, index) => ({
      id: `aiBasics-${index}`,
      header: <FormattedMessage id={item.question} />,
      body: <FormattedMessage id={item.answer} />,
      open: false,
    })),
  );

  const [promptingItems, setPromptingItems] = useState(
    promptText.promptingQuestionsAnswers.map((item, index) => ({
      id: `prompting-${index}`,
      header: <FormattedMessage id={item.question} />,
      body: <FormattedMessage id={item.answer} />,
      open: false,
    })),
  );

  const [dataProtectionItems, setDataProtectionItems] = useState(
    promptText.dataProtectionAndProcessingQuestionsAnswers.map((item, index) => ({
      id: `dataProtection-${index}`,
      header: <FormattedMessage id={item.question} />,
      body: <FormattedMessage id={item.answer} />,
      open: false,
    })),
  );

  return (
    <div className={classes.container}>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <span>
        <FormattedMessage id={promptText.introduction} />
      </span>
      <div className={classes.sectionTitle}>
        <FormattedMessage id={promptText.aiBasicsHeading} />
      </div>
      <div className={classes.panel}>
        <div>
          <ExpanderList items={aiBasicsItems} />
        </div>
      </div>
      <div className={classes.sectionTitle}>
        <FormattedMessage id={promptText.promptingHeading} />
      </div>
      <div className={classes.panel}>
        <div>
          <ExpanderList items={promptingItems} />
        </div>
      </div>
      <div className={classes.sectionTitle}>
        <FormattedMessage id={promptText.dataProtectionAndProcessingHeading} />
      </div>
      <div className={classes.panel}>
        <div>
          <ExpanderList items={dataProtectionItems} />
        </div>
      </div>
      <div className={classes.panel}>
        <PageNavigation
          onNext={handleGoToChat}
          onPrevious={handlePreviousSection}
          previousLabel="getStartedPage.howVirtualAssistantWorks.label"
          nextLabel="getStartedPage.common.startChat.label"
        />
      </div>
    </div>
  );
};

export default HowWritePromptsSection;
