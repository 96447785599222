import { Fragment } from 'react/jsx-runtime';
import TableActionBar from '../../components/organism/TableActionBar/TableActionBar';
import LimitedList from '../../components/molecule/LimitedList/LimitedList';
import styled from 'styled-components';

export const markdownRenderers = (message) => ({
  pre: ({ children }) => <div>{children}</div>,
  code: ({ children }) => <div>{children}</div>,
  a: ({ href, children }) => (
    <span className="text-capitalize">
      <a href={href} target="_blank" rel="noopener noreferrer" className="text-color-primary">
        <span className="rioglyph rioglyph-files" aria-hidden="true" />
        <span> {children}</span>
      </a>
    </span>
  ),
  p: ({ children }) => <div className="padding-y-5">{children}</div>,
  ul: ({ children }) => <LimitedList>{children}</LimitedList>,
  ol: ({ children }) => <LimitedList>{children}</LimitedList>,
  li: ({ children }) => <li className="margin-left-20">{children}</li>,
  table: ({ children }) => (
    <Fragment>
      <TableContainer className="table-responsive">
        <StyledTable className="table table-head-filled table-bordered">{children}</StyledTable>
      </TableContainer>
      <TableActionBar message={message} />
    </Fragment>
  ),
  th: ({ children }) => <th>{children}</th>,
  td: ({ children }) => <StyledTd>{children}</StyledTd>,
  tr: ({ children }) => <tr>{children}</tr>,
});

const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  word-break: break-word;
`;

const StyledTd = styled.td`
  word-break: break-word;
`;
