import { useIntl } from 'react-intl';
import ReactFlow, { Controls, Position } from 'reactflow';
import 'reactflow/dist/style.css';

const texts = {
  question: 'getStartedPage.howAssistantWorksPrompt.diagram.question',
  intentDetection: 'getStartedPage.howAssistantWorksPrompt.diagram.intentDetection',
  routing: 'getStartedPage.howAssistantWorksPrompt.diagram.routing',
  answer: 'getStartedPage.howAssistantWorksPrompt.diagram.answer',
  systemTraining: 'getStartedPage.howAssistantWorksPrompt.diagram.systemTraining',
  systemHelp: 'getStartedPage.howAssistantWorksPrompt.diagram.systemHelp',
  systemPerform: 'getStartedPage.howAssistantWorksPrompt.diagram.systemPerform',
  annotation1: 'getStartedPage.howAssistantWorksPrompt.diagram.annotation1',
  annotation2: 'getStartedPage.howAssistantWorksPrompt.diagram.annotation2',
  annotation3: 'getStartedPage.howAssistantWorksPrompt.diagram.annotation3',
  annotation4: 'getStartedPage.howAssistantWorksPrompt.diagram.annotation4',
  annotation5: 'getStartedPage.howAssistantWorksPrompt.diagram.annotation5',
} as const;

const AnimatedFlow = () => {
  const intl = useIntl();

  const createNodes = (intl) => [
    {
      id: '1',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.question }) },
      position: { x: 330, y: -100 },
      style: {
        background: 'var(--brand-info)',
        color: 'var(--color-white)',
        borderRadius: '12px',
        width: '180px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
      },
    },
    {
      id: 'group1',
      type: 'group',
      data: { label: '' },
      position: { x: 200, y: 150 },
      style: {
        width: 400,
        height: 220,
        backgroundColor: 'rgba(235, 249, 250, 0.5)',
        border: '2px solid var(--color-highlight-darkest)',
        borderRadius: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      },
    },
    {
      id: '2',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.intentDetection }) },
      position: { x: 20, y: 50 },
      parentId: 'group1',
      sourcePosition: Position.Right,
      style: {
        backgroundColor: 'var(--color-white)',
        border: '2px solid #6495ed',
        borderRadius: '8px',
        width: '150px',
        fontSize: '14px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
    },
    {
      id: '3',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.routing }) },
      position: { x: 220, y: 50 },
      parentId: 'group1',
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      style: {
        backgroundColor: 'var(--color-white)',
        border: '2px solid #6495ed',
        borderRadius: '8px',
        width: '150px',
        fontSize: '14px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
    },
    {
      id: 'group2',
      type: 'group',
      data: { label: '' },
      position: { x: 700, y: 110 },
      style: {
        width: 260,
        height: 290,
        backgroundColor: 'rgba(240, 248, 255, 0.5)',
        border: '2px solid var(--color-coldplay-fountain)',
        borderRadius: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      },
    },
    {
      id: '4a',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.systemTraining }) },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      position: { x: 40, y: 35 },
      parentId: 'group2',
      style: {
        backgroundColor: 'var(--color-white)',
        border: '2px solid #6495ed',
        borderRadius: '8px',
        width: '180px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
    },
    {
      id: '4b',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.systemHelp }) },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      position: { x: 40, y: 120 },
      parentId: 'group2',
      style: {
        backgroundColor: 'var(--color-white)',
        border: '2px solid #6495ed',
        borderRadius: '8px',
        width: '180px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
    },
    {
      id: '4c',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.systemPerform }) },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      position: { x: 40, y: 210 },
      parentId: 'group2',
      style: {
        backgroundColor: 'var(--color-white)',
        border: '2px solid #6495ed',
        borderRadius: '8px',
        width: '180px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      },
    },
    {
      id: '5',
      type: 'default',
      data: { label: intl.formatMessage({ id: texts.answer }) },
      targetPosition: Position.Left,
      position: { x: 1100, y: 230 },
      style: {
        background: 'var(--brand-info)',
        color: 'var(--color-white)',
        borderRadius: '12px',
        width: '150px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
      },
    },
    {
      id: 'annotation-1',
      type: 'annotation',
      data: { text: intl.formatMessage({ id: texts.annotation1 }) },
      position: { x: 350, y: -45 },
    },
    {
      id: 'annotation-2',
      type: 'annotation',
      data: { text: intl.formatMessage({ id: texts.annotation2 }) },
      position: { x: 225, y: 260 },
    },
    {
      id: 'annotation-3',
      type: 'annotation',
      data: { text: intl.formatMessage({ id: texts.annotation3 }) },
      position: { x: 425, y: 260 },
    },
    {
      id: 'annotation-4',
      type: 'annotation',
      data: { text: intl.formatMessage({ id: texts.annotation4 }) },
      position: { x: 760, y: 420 },
    },
    {
      id: 'annotation-5',
      type: 'annotation',
      data: { text: intl.formatMessage({ id: texts.annotation5 }) },
      position: { x: 1105, y: 285 },
    },
  ];

  const edges = [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      animated: true,
      type: 'smoothstep',
      style: { stroke: 'var(--color-highlight-darkest)' },
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      animated: true,
      type: 'smoothstep',
      style: { stroke: 'var(--color-highlight-darkest)' },
    },
    {
      id: 'e3-4a',
      source: '3',
      target: '4a',
      animated: true,
      type: 'smoothstep',
      style: { stroke: 'var(--brand-info)' },
    },
    {
      id: 'e3-4b',
      source: '3',
      target: '4b',
      animated: true,
      type: 'smoothstep',
      style: { stroke: 'var(--brand-info)' },
    },
    {
      id: 'e3-4c',
      source: '3',
      target: '4c',
      animated: true,
      type: 'smoothstep',
      style: { stroke: 'var(--brand-info)' },
    },
    {
      id: 'e4a-5',
      source: '4a',
      target: '5',
      animated: true,
      style: { stroke: 'var(--brand-info)', strokeDasharray: '5,5' },
    },
    {
      id: 'e4b-5',
      source: '4b',
      target: '5',
      animated: true,
      style: { stroke: 'var(--brand-info)', strokeDasharray: '5,5' },
    },
    {
      id: 'e4c-5',
      source: '4c',
      target: '5',
      animated: true,
      style: { stroke: 'var(--brand-info)', strokeDasharray: '5,5' },
    },
  ];

  const AnnotationNode = ({ data }) => (
    <div
      style={{
        padding: '10px',
        backgroundColor: 'var(--gray-lightest)',
        border: '1px solid var(--gray-light)',
        borderRadius: '10px',
        boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
        fontSize: '12px',
        maxWidth: '140px',
        textAlign: 'center',
      }}
    >
      {data.text}
    </div>
  );

  const nodeTypes = {
    annotation: AnnotationNode,
  };

  const nodes = createNodes(intl);

  return (
    <div style={{ height: '700px', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        style={{ backgroundColor: 'var(--gray-lightest)' }}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default AnimatedFlow;
