import { FormattedMessage, useIntl } from 'react-intl';
import { ChatThread } from '../../../store/thread/threadSlice';
import ThreadListItem from '../../organism/ThreadListItem/ThreadListItem';

type ChatThreadCategoryProps = {
  threads: ChatThread[];
  title: string;
};

const ChatThreadCategory = ({ title, threads }: ChatThreadCategoryProps) => {
  const intl = useIntl();
  return (
    <div>
      <p className="text-bold padding-x-10">{intl.formatMessage({ id: title })}</p>
      <ul className="overflow-auto list-group padding-0">
        {threads.map((thread) =>
          thread.title ? (
            <ThreadListItem
              key={`item-${thread.id}`}
              localId={thread.id}
              threadId={thread.threadId}
              firstMessage={thread.title}
              isFavorite={thread.isFavorite}
            />
          ) : null,
        )}
      </ul>
    </div>
  );
};

export default ChatThreadCategory;
