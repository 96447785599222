import Dialog from '@rio-cloud/rio-uikit/Dialog';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import Button from '@rio-cloud/rio-uikit/Button';
import { useState, useRef } from 'react';

const IframeTestPage = () => {
  const [showDialog, setShowDialog] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleOpenInNewWindow = () => {
    const currentIframeUrl = 'https://fleetassistant.rio.cloud/';
    window.open(currentIframeUrl, '_blank');
  };

  const body = (
    <div className="iframe-wrapper">
      <iframe
        title="fullscreen dialog"
        className="unstyled bg-lighter full-window no-application-header"
        src={'https://fleetassistant.rio.cloud/'}
      />
    </div>
  );

  const headerButtons = (
    <OverlayTrigger placement="bottom" overlay={<Tooltip width="auto">Open in new window</Tooltip>}>
      <Button bsStyle="muted" iconOnly onClick={handleOpenInNewWindow}>
        <span className="rioglyph rioglyph-new-window" />
      </Button>
    </OverlayTrigger>
  );

  return (
    <div className="padding-20 height-75vh width-100vw display-flex flex-column justify-content-center align-items-center">
      <p className="margin-bottom-10 text-16 max-width-40pct text-center margin-bottom-20">
        This is a test route to evaluate how the application performs when integrated in an iframe. Use the button below
        to open the application in fullscreen mode within an iframe.
      </p>
      <Button onClick={() => setShowDialog(true)}>
        <span>Open Fleet Assistant in fullscreen dialog</span>
      </Button>
      <Dialog
        show={showDialog}
        title="Fleet Assistant"
        bodyClassName="padding-0"
        body={body}
        onClose={() => setShowDialog(false)}
        showCloseButton
        bsSize={Dialog.SIZE_FULL_SCREEN}
        headerButtons={headerButtons}
      />
    </div>
  );
};

export default IframeTestPage;
