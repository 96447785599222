import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getLocale } from '../../../configuration/lang/langSlice';

const texts = {
  title: 'getStartedPage.newFeaturesSection.title',
  description: 'getStartedPage.newFeaturesSection.description',
  howItWorksLabel: 'getStartedPage.howVirtualAssistantWorks.label',
  features: [
    'getStartedPage.newFeaturesSection.partOne',
    'getStartedPage.newFeaturesSection.partTwo',
    'getStartedPage.newFeaturesSection.partThree',
    'getStartedPage.newFeaturesSection.partFour',
    'getStartedPage.newFeaturesSection.partFive',
  ],
};

const NewFeaturesSection = () => {
  const isDarkMode = useDarkMode();
  const currentLanguage = useAppSelector(getLocale);

  return (
    <Container isDarkMode={isDarkMode}>
      <TextContainer>
        <Heading isDarkMode={isDarkMode}>
          <FormattedMessage id={texts.title} />
        </Heading>
        <Text isDarkMode={isDarkMode}>
          <FormattedMessage id={texts.description} />
          <ul className="margin-top-5">
            {texts.features.map((feature, index) => (
              <li key={`feature-li-${index}`}>
                <div key={`feature-div-${index}`}>
                  <span key={`feature-span-${index}`}>
                    <FormattedMessage id={feature} key={`feature-${index}`} />
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </Text>
      </TextContainer>
      <ImageContainer>
        <img
          src={currentLanguage === 'de-DE' ? 'assets/images/screenshot_de.png' : 'assets/images/screenshot_en.png'}
          alt=""
          height={200}
        />
      </ImageContainer>
    </Container>
  );
};

export default NewFeaturesSection;

const Container = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  padding: 4rem;
  background: ${(props) => (!props.isDarkMode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  gap: 3rem;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 1rem;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 3rem;

  @media (max-width: 1024px) {
    padding-right: 0;
    text-align: center;
  }
`;

const Heading = styled.h3<{ isDarkMode: boolean }>`
  margin-bottom: 1rem;
  text-align: left;
  color: ${(props) => (props.isDarkMode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const Text = styled.p<{ isDarkMode: boolean }>`
  line-height: 1.5;
  max-width: 500px;
  color: ${(props) => (props.isDarkMode ? 'var(--color-black)' : 'var(--color-white)')};
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: right;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 1024px) {
    text-align: center;
    margin-bottom: 2rem;
  }
`;
