export const allSuggestionsDE = [
  'letzter Monat',
  'letztes Jahr',
  'letzte Woche',
  'letztes Quartal',
  'letztes Jahrzehnt',
  'letzter Sprint',
  'letzte Veröffentlichung',
  'vor einer Woche',
  'vor zwei Wochen',
  'gestern',
  'vor drei Monaten',
  'vor sechs Monaten',
  'letzten Monat',
  'letztes Quartalsmonat',
  'durchschnittlicher Einsatzverbrauch',
  'Kraftstoffverbrauch',
  'durchschnittlicher Gesamtkraftstoffverbrauch',
  'durchschnittlicher Standverbrauch',
  'Schubbetrieb Verbrauch',
  'Tempomat Verbrauch',
  'Overspeed Verbrauch',
  'Gesamtkilometerzahl',
  'zurückgelegte Strecke',
  'totale Betriebszeit',
  'Fahrzeit',
  'Leerlaufzeit',
  'übermäßige Leerlaufzeit',
  'übermäßige Fahrstunden',
  'Gesamtzahl der Fahrten',
  'Business Report Format',
  'Leistungsbericht',
  'Übersicht',
  'Tabelle mit den Gesamtbewertungen',
  'Tabelle mit den Werten für Tempomat',
  'Tabelle mit den Werten für Overspeed',
  'Bremsmuster',
  'Schubbetrieb Verhalten',
  'Bremsrating',
  'Beschleunigungsverhalten',
  'zu schnelles Fahren Verhalten',
  'Verbesserungstendenzen',
  'vergleiche mit dem vorherigen Quartal',
  'vergleiche die Verbrauchswerte',
  'vergleiche die Fahrleistung der Fahrer über die Zeit',
  'vergleiche Bremsmuster',
  'identifiziere Trends oder Auffälligkeiten',
  'analysiere den durchschnittlichen Verbrauch',
  'zeige die Verbesserungstendenzen',
  'kontaktiere den Mobile 24 Service telefonisch',
  'starte und initiere einen Lichttest',
  'berücksichtige extrem niedrige oder hohe Außentemperaturen',
  'verbessere die Betriebssicherheit',
  'vergleiche die Fahrleistung der Fahrer über die Zeit',
  'Kontaktmöglichkeiten',
  'Überlegungen bei extremen Temperaturen',
  'Schubbetrieb',
  'Tempomat',
  'Overspeed',
  'Bremsrating',
  'Schlüsselkennzahlen',
  'Wie können Fahrer ihren Schubbetrieb verbessern?',
  'Wie wird das Bremsrating zusammengesetzt?',
  'Erstelle eine Tabelle mit den Werten für Tempomat',
  'Fahrer benötigen Unterstützung aufgrund einer Gesamtbewertung unter 70',
  'liste ihre Bewertungen auf',
  'Bitte nenne alle Kontaktmöglichkeiten.',
  'Wie kann ich den Mobile 24 Service kontaktieren?',
  'Tabelle anzeigen',
  'Gestalten Sie es wie einen Business-Bericht',
];
