import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCurrentPage, selectCurrentPageProps } from '../../../store/about/aboutSlice';
import { ComponentToElement } from '../../../store/about/pageToContent';
import HowAssistantWorks from '../../organism/HowAssistantWorks/HowAssistantWorks';
import WhatIsFleetAssistant from '../../organism/WhatIsFleetAssistant/WhatIsFleetAssistant';
import styled from 'styled-components';
import FrequentlyAskedQuestions from '../../organism/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import Welcome from '../../organism/Welcome/Welcome';

export const aboutMap: ComponentToElement = {
  welcomePage: <Welcome />,
  fleetAssistantOverview: <WhatIsFleetAssistant />,
  assistantFunctionality: <HowAssistantWorks />,
  frequentlyAskedQuestions: <FrequentlyAskedQuestions />,
};

const GetStartedPage = () => {
  const activePage = useSelector(selectCurrentPage);
  const activePageProps = useSelector(selectCurrentPageProps);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePage]);

  return (
    <Container>
      <Article>
        {activePage !== 'page1' ? (
          <h2 className="h2 margin-bottom-15 margin-top-0">
            <FormattedMessage id={activePageProps.translation} />
          </h2>
        ) : null}
        {aboutMap[activePageProps.componentId]}
      </Article>
    </Container>
  );
};

export default GetStartedPage;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: start;
`;

const Article = styled.div`
  max-width: 1280px;
`;
