import styled from 'styled-components';

type ServiceCardProps = {
  description: string;
  onClickFunction: (value: string) => void;
};

const ServiceCard = ({ description, onClickFunction }: ServiceCardProps) => {
  return (
    <ServiceCardWrapper
      className="
            panel panel-primary panel-body panel-blank shadow-default padding-15 margin-bottom-0
            position-relative hover-scale-105 transition-all transition-duration-03 transition-ease-in-out 
        "
      onClick={() => onClickFunction(description)}
    >
      <span className="position-absolute left--1 top-15 height-25 width-3 bg-primary" />
      <StyledParagraph className="text-color-darker margin-top-6 text-size-12">{description}</StyledParagraph>
    </ServiceCardWrapper>
  );
};

export default ServiceCard;

const ServiceCardWrapper = styled.div`
  cursor: pointer;
  max-width: 180px;
  height: 180px;
`;

const StyledParagraph = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  margin: 0;
`;
