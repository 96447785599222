import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TreeItem } from '@rio-cloud/rio-uikit/components/assetTree/Tree';

type FAQsState = TreeItem[];

const initialState: FAQsState = [];

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    addQuestions: (state, action: PayloadAction<TreeItem[]>) => {
      return [...state, ...action.payload];
    },
  },
});

export const { addQuestions } = faqSlice.actions;

export const selectAllFAQs = (state: RootState) => state.faq;

export default faqSlice.reducer;
