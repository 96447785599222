declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export interface GAEvent {
  category: string;
  action: string;
  label: string;
}

export const gaPush = (gaEvent: GAEvent) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'ga_event',
      eventCategory: gaEvent.category,
      eventAction: gaEvent.action,
      eventLabel: gaEvent.label,
    });
  }
};

export const executeAndTrack =
  (func: Function, value: GAEvent) =>
  (...args: any) => {
    func.apply(null, args);
    gaPush(value);
  };

export interface TrackingValues {
  trigger: 'click' | 'visibility';
  category: string;
  action: string;
  label: string;
  value: string;
}

export interface TrackingAttributes {
  'data-track-ga-event-trigger': string;
  'data-track-ga-event-category': string;
  'data-track-ga-event-action': string;
  'data-track-ga-event-label'?: string;
  'data-track-ga-event-value'?: string;
}

export const getTrackingAttributes = (param: TrackingValues) => {
  const { trigger, category, action, label, value } = param;

  const attributes: TrackingAttributes = {
    'data-track-ga-event-trigger': trigger,
    'data-track-ga-event-category': category,
    'data-track-ga-event-action': action || `action_${trigger}`,
  };

  if (label) {
    attributes['data-track-ga-event-label'] = `additional::${label}`;
  }

  if (value) {
    attributes['data-track-ga-event-value'] = value;
  }

  return attributes;
};

export const AUTOCOMPLETE_CATEGORY = 'Autocomplete';
export const COMMENT_DIALOG_CATEGORY = 'CommentDialog';
export const TABLE_ACTION_BAR_CATEGORY = 'TableActionBar';
export const MESSAGE_ACTION_BAR_CATEGORY = 'MessageActionBar';
export const SIDEBAR_CATEGORY = 'Sidebar';
export const PAGE_NAVIGATION_CATEGORY = 'PageNavigation';
export const ABOUT_NAVIGATION_CATEGORY = 'AboutNavigation';
export const FREQUENTLY_ASKED_QUESTIONS_CATEGORY = 'FrequentlyAskedQuestions';
export const GET_STARTED_SECTION_CATEGORY = 'GetStartedSection';

export const AUTOCOMPLETE_ACTIONS = {
  SUGGESTIONS_SHOWN: 'Suggestions shown',
  ACCEPT_SUGGESTION: 'Accept suggestion',
  TYPED_MANUALLY: 'Typed manually (same as suggestion)',
  ARROW_NAVIGATION: 'Arrow navigation in suggestions',
  CLOSE_WITHOUT_USE: 'Close suggestions without usage',
};

export const COMMENT_DIALOG_ACTIONS = {
  SUBMIT_COMMENT: 'Submit Comment',
  SHOW_MORE_ANSWER: 'Show More Answer',
  SHOW_LESS_ANSWER: 'Show Less Answer',
};

export const TABLE_ACTION_BAR_ACTIONS = {
  DOWNLOAD_EXCEL: 'Download Excel',
  DOWNLOAD_PDF: 'Download PDF',
};

export const MESSAGE_ACTION_BAR_ACTIONS = {
  DOWNLOAD_PDF: 'Download Answer as PDF',
  THUMBS_UP: 'Thumbs Up Feedback',
  THUMBS_DOWN: 'Thumbs Down Feedback',
  COMMENT: 'Comment Feedback',
};

export const SIDEBAR_ACTIONS = {
  SELECT_CATEGORY: 'Select Category',
};

export const PAGE_NAVIGATION_ACTIONS = {
  CLICK_PREVIOUS: 'Click Previous',
  CLICK_NEXT: 'Click Next',
};

export const ABOUT_NAVIGATION_ACTIONS = {
  CLICK_PAGE: 'Click Page',
};

export const FREQUENTLY_ASKED_QUESTIONS_ACTIONS = {
  CLICK_START_CHAT: 'Click Start Chat',
};

export const GET_STARTED_SECTION_ACTIONS = {
  CLICK_START_CHAT: 'Click Start Chat',
};
