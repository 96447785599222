import ApplicationLayout from '@rio-cloud/rio-uikit/components/applicationLayout/ApplicationLayout';
import AssetTree from '@rio-cloud/rio-uikit/components/assetTree/AssetTree';
import { selectIsSidebarOpen, toggleSidebar } from '../../../store/sidebar/sidebarSlice';
import { useIntl } from 'react-intl';
import { selectCurrentLayout, setCurrentLayout } from '../../../store/layout/layoutSlice';
import { LayoutItem, sidebarToContent } from '../../../store/layout/sidebarToContent';
import { componentMap } from '../../pages/ChatPage/ChatPage';
import { setCurrentPage } from '../../../store/about/aboutSlice';
import { Steps } from '../../../store/onboarding/onboardingSlice';
import TreeCategory from '@rio-cloud/rio-uikit/components/assetTree/TreeCategory';
import { gaPush, SIDEBAR_ACTIONS, SIDEBAR_CATEGORY } from '../../../configuration/setup/googleAnalytics';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

type ChatAssetTreeProps = {
  handleSend: (content: string, isOnboarding?: boolean, nextStepIndex?: Steps) => Promise<void>;
  isLoading: boolean;
};

const ChatAssetTree = ({ isLoading, handleSend }: ChatAssetTreeProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isTreeOpen = useAppSelector(selectIsSidebarOpen);
  const activeSidebarItem = useAppSelector(selectCurrentLayout);

  const handleToggleTree = (toggle: boolean) => dispatch(toggleSidebar());
  const handleOnChange = (selectCategoryId: string) => {
    dispatch(setCurrentLayout(selectCategoryId as LayoutItem));
    if (activeSidebarItem !== 'about') dispatch(setCurrentPage('page1'));
    gaPush({
      category: SIDEBAR_CATEGORY,
      action: SIDEBAR_ACTIONS.SELECT_CATEGORY,
      label: `Category ID: ${selectCategoryId}`,
    });
  };

  return (
    <ApplicationLayout.Sidebar>
      {activeSidebarItem ? (
        <AssetTree
          minWidth={300}
          maxWidth={450}
          currentCategoryId={activeSidebarItem}
          onCategoryChange={handleOnChange}
          isOpen={isTreeOpen}
          onToggleTree={handleToggleTree}
          useOffscreen
        >
          {Object.entries(sidebarToContent).map(([key, item]) => {
            const SidebarComponent = componentMap[key as LayoutItem]?.sidebarComponent;

            return (
              <TreeCategory
                key={`${key}-sidebar`}
                id={key}
                label={intl.formatMessage({ id: item.translation })}
                icon={item.icon}
              >
                {SidebarComponent && <SidebarComponent handleSend={handleSend} isLoading={isLoading} />}
              </TreeCategory>
            );
          })}
        </AssetTree>
      ) : null}
    </ApplicationLayout.Sidebar>
  );
};

export default ChatAssetTree;
