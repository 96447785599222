import { FormattedMessage } from 'react-intl';
import {
  gaPush,
  PAGE_NAVIGATION_ACTIONS,
  PAGE_NAVIGATION_CATEGORY,
} from '../../../configuration/setup/googleAnalytics';

type PageNavigationProps = {
  onNext: () => void;
  onPrevious: () => void;
  previousLabel: string;
  nextLabel: string;
};

const PageNavigation = ({ onNext, onPrevious, previousLabel, nextLabel }: PageNavigationProps) => {
  const handlePreviousClick = () => {
    gaPush({
      category: PAGE_NAVIGATION_CATEGORY,
      action: PAGE_NAVIGATION_ACTIONS.CLICK_PREVIOUS,
      label: `Prev: ${previousLabel}`,
    });

    onPrevious();
  };

  const handleNextClick = () => {
    gaPush({
      category: PAGE_NAVIGATION_CATEGORY,
      action: PAGE_NAVIGATION_ACTIONS.CLICK_NEXT,
      label: `Next: ${nextLabel}`,
    });

    onNext();
  };

  return (
    <div className="display-flex gap-20 margin-bottom-50 width-100pct justify-content-between">
      <div
        className="Pager bg-white border display-flex justify-space-between align-items-center flex-row-reverse cursor-pointer user-select-none rounded padding-y-15 padding-x-20 width-350"
        onClick={handlePreviousClick}
      >
        <div className="display-flex flex-1-1 flex-column align-items-end">
          <div className="text-color-gray line-height-16">
            <FormattedMessage id="getStartedPage.pageNavigation.previous.label" />
          </div>
          <div className="text-color-darker text-right text-size-16 text-medium">
            <FormattedMessage id={previousLabel} />
          </div>
        </div>
        <div className="text-size-12">
          <span className="rioglyph text-color-darker rioglyph-arrow-left margin-right-10 text-size-200pct" />
        </div>
      </div>
      <div
        className="Pager bg-white border display-flex justify-space-between align-items-center cursor-pointer user-select-none rounded padding-y-15 padding-x-20 width-350"
        onClick={handleNextClick}
      >
        <div className="display-flex flex-1-1 flex-column">
          <div className="text-color-gray line-height-16">
            <FormattedMessage id="getStartedPage.pageNavigation.next.label" />
          </div>
          <div className="text-color-darker text-size-16 text-medium">
            <FormattedMessage id={nextLabel} />
          </div>
        </div>
        <div className="text-size-12">
          <span className="rioglyph text-color-darker rioglyph-arrow-right margin-left-10 text-size-200pct" />
        </div>
      </div>
    </div>
  );
};

export default PageNavigation;
