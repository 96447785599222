import { allSuggestionsDE } from './allSuggestionsDE';
import { allSuggestionsEN } from './allSuggestionsEN';

export const fetchSuggestions = (lastWord: string, language: string): string[] => {
  if (!lastWord) return [];
  const lowerCaseLastWord = lastWord.toLowerCase();

  const suggestions = (language === 'de-DE' ? allSuggestionsDE : allSuggestionsEN).filter(
    (suggestion) =>
      suggestion.toLowerCase().startsWith(lowerCaseLastWord) && suggestion.toLowerCase() !== lowerCaseLastWord,
  );

  return suggestions;
};
