export const layoutItems = ['chatHistory', 'favorites', 'faq', 'about', 'feedback', 'onboardingMode'] as const;

export type LayoutItem = (typeof layoutItems)[number];

export type LayoutProps = {
  translation: string;
  icon: string;
};

type VariantConfig = {
  [key in LayoutItem]: LayoutProps;
};

export const sidebarToContent: VariantConfig = {
  chatHistory: {
    translation: 'chatPage.sidebar.history.label',
    icon: 'rioglyph-chat',
  },
  favorites: {
    translation: 'chatPage.sidebar.favorites.label',
    icon: 'rioglyph-star',
  },
  faq: {
    translation: 'chatPage.sidebar.faq.label',
    icon: 'rioglyph-search-list',
  },
  about: {
    translation: 'chatPage.sidebar.about.label',
    icon: 'rioglyph-info-sign',
  },
  feedback: {
    translation: 'chatPage.sidebar.feedback.label',
    icon: 'rioglyph-send',
  },
  onboardingMode: {
    translation: 'chatPage.sidebar.onboardingMode.label',
    icon: 'rioglyph-onboarding',
  },
} as const;
