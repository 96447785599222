import { Route, Routes } from 'react-router-dom';
import AppLayout from './AppLayout';
import ChatPage from '../components/pages/ChatPage/ChatPage';
import routes from '../routes/routes';
import ProtectedRoute from '../routes/ProtectedRoute/ProtectedRoute';
import IframeTestPage from '../components/pages/IframeTestPage/IframeTestPage';

const isIframeTestRouteEnabled = import.meta.env.VITE_FEATURE_FLAG_IFRAME_TEST_ROUTE_ENABLED;

const App = () => (
  <Routes>
    <Route path="/" element={<AppLayout />}>
      <Route
        path={routes.homepage}
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      {isIframeTestRouteEnabled ? (
        <Route
          path={routes.iframeTest}
          element={
            <ProtectedRoute>
              <IframeTestPage />
            </ProtectedRoute>
          }
        />
      ) : null}
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
    </Route>
  </Routes>
);

export default App;
