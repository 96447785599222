export const allSuggestionsEN = [
  'last month',
  'last year',
  'last week',
  'last quarter',
  'last decade',
  'last sprint',
  'last release',
  'one week ago',
  'two weeks ago',
  'yesterday',
  'three months ago',
  'six months ago',
  'last month',
  'last quarter month',
  'consumption',
  'average operating consumption',
  'operating consumption',
  'fuel consumption',
  'average total fuel consumption',
  'average idling consumption',
  'coasting consumption',
  'cruise control consumption',
  'overspeed consumption',
  'total mileage',
  'distance travelled',
  'total operating time',
  'driving time',
  'idling time',
  'excessive time idling',
  'excessive driving hours',
  'total number of trips',
  'business report format',
  'performance report',
  'overview',
  'table with the total ratings',
  'table with the values for cruise control',
  'table with the values for overspeed',
  'braking patterns',
  'coasting behavior',
  'braking rating',
  'accelerating behavior',
  'speeding behavior',
  'improvements trends',
  'compare it with the previous quarter',
  'compare the consumption values',
  'compare driver performance over time',
  'compare braking patterns',
  'identify any trends or anomalies',
  'analyze the average consumption',
  'show the improvements trends',
  'contact the Mobile 24 service via telephone',
  'start and initiate a light test',
  'consider at extremely low or high outside temperatures',
  'improve operational safety',
  'compare driver performance over time',
  'contact options',
  'considerations for extreme temperatures',
  'coasting',
  'cruise control',
  'overspeed',
  'braking rating',
  'key figures',
  'what can my drivers do to improve coasting?',
  'how is the braking rating put together?',
  'create a table with the values for cruise control',
  'drivers need support due to an overall rating below 70',
  'list their ratings',
  'please state all contact options',
  'show table',
  'make it look like a business report',
];
