import { ChangeEvent, KeyboardEventHandler, useState, useEffect, useRef, FocusEventHandler } from 'react';
import styled from 'styled-components';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AUTOCOMPLETE_ACTIONS, AUTOCOMPLETE_CATEGORY, gaPush } from '../../../configuration/setup/googleAnalytics';
import { fetchSuggestions } from '../../../utils/suggestions/fetchSuggestions';
import { useIsDemoThread } from '../../../hooks/useIsDemoThread';
import { getLocale } from '../../../configuration/lang/langSlice';
import { selectActiveThread } from '../../../store/thread/threadSlice';
import { useAppSelector } from '../../../configuration/setup/hooks';

type ChatInputProps = {
  isLoading: boolean;
  onSendMessage: (message: string, localId: string | null) => void;
  onSendFile?: (file: File) => void;
};

const validationRules = {
  maxMessageLength: 300,
  allowedFileType: 'application/pdf',
  maxFileSizeMB: 5,
};

const errorMessages = {
  emptyMessage: 'chatPage.chatInput.validation.empty.error',
  messageTooLong: 'chatPage.chatInput.validation.tooLong.error',
  invalidFileType: 'chatPage.chatInput.validation.invalidType.error',
  fileTooLarge: 'chatPage.chatInput.validation.tooLarge.error',
};

const ChatInput = ({ isLoading, onSendMessage, onSendFile }: ChatInputProps) => {
  const intl = useIntl();
  const activeThread = useAppSelector(selectActiveThread);
  const localId = activeThread ? activeThread.id : null;
  const isDemoThread = useIsDemoThread(localId);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const inputRef = useRef<HTMLInputElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);
  const debounceTimeout = useRef<number | undefined>(undefined);

  const lastSuggestionsRef = useRef<string[]>([]);

  const [hasAcceptedSuggestion, setHasAcceptedSuggestion] = useState(false);

  const currentLanguage = useSelector(getLocale);
  const [typedTextWidth, setTypedTextWidth] = useState(0);

  const showErrorMessage = (errorMessage: string) => {
    setError(errorMessage);
    Notification.error(<span>{intl.formatMessage({ id: errorMessage })}</span>);
  };

  const validateFile = (file: File): boolean => {
    if (file.type !== validationRules.allowedFileType) {
      showErrorMessage(errorMessages.invalidFileType);
      return false;
    }
    if (file.size > validationRules.maxFileSizeMB * 1024 * 1024) {
      showErrorMessage(errorMessages.fileTooLarge);
      return false;
    }
    return true;
  };

  const sanitizeInput = (value: string) => value.replace(/[<>{}]/g, '');

  const validateInput = (value: string) => {
    if (!value.trim()) {
      showErrorMessage(errorMessages.emptyMessage);
      return false;
    }
    if (value.length > validationRules.maxMessageLength) {
      showErrorMessage(errorMessages.messageTooLong);
      return false;
    }
    return true;
  };

  const handleSend = (trigger: 'enter' | 'button') => {
    if (validateInput(inputValue)) {
      onSendMessage(inputValue, localId);

      if (!hasAcceptedSuggestion && lastSuggestionsRef.current.length > 0) {
        const typedSuggestionManually = lastSuggestionsRef.current.some(
          (suggestion) => suggestion.trim().toLowerCase() === inputValue.trim().toLowerCase(),
        );

        if (typedSuggestionManually) {
          gaPush({
            category: AUTOCOMPLETE_CATEGORY,
            action: AUTOCOMPLETE_ACTIONS.TYPED_MANUALLY,
            label: inputValue,
          });
        }
      }

      setInputValue('');
      setSuggestions([]);
      setActiveSuggestionIndex(-1);
      setHasAcceptedSuggestion(false);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Backspace') {
      return;
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      setSuggestions([]);
      setActiveSuggestionIndex(-1);
      return;
    }

    if (suggestions.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          const newIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;

          gaPush({
            category: AUTOCOMPLETE_CATEGORY,
            action: AUTOCOMPLETE_ACTIONS.ARROW_NAVIGATION,
            label: `Index: ${newIndex}`,
          });

          return newIndex;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveSuggestionIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;

          gaPush({
            category: AUTOCOMPLETE_CATEGORY,
            action: AUTOCOMPLETE_ACTIONS.ARROW_NAVIGATION,
            label: `Index: ${newIndex}`,
          });

          return newIndex;
        });
      } else if (e.key === 'Enter') {
        if (!isLoading) {
          handleSend('enter');
        }
      } else if (e.key === 'Tab' || e.key === 'ArrowRight') {
        if (activeSuggestionIndex >= 0) {
          e.preventDefault();
          applySuggestion(suggestions[activeSuggestionIndex]);
        }
      }
    } else if (e.key === 'Enter' && !isLoading) {
      handleSend('enter');
    }
  };

  const applySuggestion = (suggestionText: string) => {
    const lastSpaceIndex = inputValue.lastIndexOf(' ');
    const inputWithoutLastWord = lastSpaceIndex !== -1 ? inputValue.substring(0, lastSpaceIndex + 1) : '';

    const newInputValue = inputWithoutLastWord + suggestionText.trim() + ' ';
    setInputValue(newInputValue);
    setSuggestions([]);
    setActiveSuggestionIndex(-1);
    setHasAcceptedSuggestion(true);

    gaPush({
      category: AUTOCOMPLETE_CATEGORY,
      action: AUTOCOMPLETE_ACTIONS.ACCEPT_SUGGESTION,
      label: suggestionText,
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;

    if (target.files && target.files[0]) {
      const file = target.files[0];
      if (validateFile(file)) {
        onSendFile && onSendFile(file);
      }
      target.value = '';
    } else {
      const sanitizedValue = sanitizeInput(value.slice(0, validationRules.maxMessageLength));
      setInputValue(sanitizedValue);
      if (error) {
        setError('');
      }

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = window.setTimeout(() => {
        const words = sanitizedValue.split(/\s+/);
        const lastWord = words[words.length - 1];

        const newSuggestions = fetchSuggestions(lastWord, currentLanguage || 'en-GB');

        if (newSuggestions.length > 0) {
          gaPush({
            category: AUTOCOMPLETE_CATEGORY,
            action: AUTOCOMPLETE_ACTIONS.SUGGESTIONS_SHOWN,
            label: newSuggestions.join(', '),
          });

          lastSuggestionsRef.current = newSuggestions;
        }

        setSuggestions(newSuggestions);
        setActiveSuggestionIndex(newSuggestions.length > 0 ? 0 : -1);
      }, 300);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        if (suggestions.length > 0) {
          setSuggestions([]);
          setActiveSuggestionIndex(-1);

          if (!hasAcceptedSuggestion) {
            gaPush({
              category: AUTOCOMPLETE_CATEGORY,
              action: AUTOCOMPLETE_ACTIONS.CLOSE_WITHOUT_USE,
              label: 'Click outside',
            });
          }
        }
      }
    };

    const handleRawEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && suggestions.length > 0) {
        setSuggestions([]);
        setActiveSuggestionIndex(-1);

        if (!hasAcceptedSuggestion) {
          gaPush({
            category: AUTOCOMPLETE_CATEGORY,
            action: AUTOCOMPLETE_ACTIONS.CLOSE_WITHOUT_USE,
            label: 'Escape key',
          });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keydown', handleRawEscape);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keydown', handleRawEscape);
    };
  }, [suggestions, hasAcceptedSuggestion]);

  useEffect(() => {
    if (measureRef.current) {
      setTypedTextWidth(measureRef.current.scrollWidth);
    }
  }, [inputValue]);

  const inputPaddingLeft = 12;
  const inputPaddingTop = 8;

  const currentSuggestion =
    suggestions.length > 0 && activeSuggestionIndex >= 0 ? suggestions[activeSuggestionIndex] : '';

  const words = inputValue.split(/\s+/);
  const lastWord = words[words.length - 1].toLowerCase();

  let remainder = currentSuggestion.trim();
  if (currentSuggestion.toLowerCase().startsWith(lastWord) && lastWord.length > 0) {
    remainder = currentSuggestion.substring(lastWord.length);
  }

  return (
    <div>
      <InputContainer>
        <StyledInputWrapper ref={inputRef}>
          <MeasureText ref={measureRef}>{inputValue}</MeasureText>
          <StyledInput
            id="chat-input"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            maxLength={validationRules.maxMessageLength}
            autoComplete="off"
            aria-autocomplete="list"
            aria-controls="suggestions-list"
            aria-expanded={suggestions.length > 0}
            aria-haspopup="listbox"
            data-testid="input-send-message"
          />
          {currentSuggestion && remainder && (
            <SuggestionOverlay
              style={{
                left: `${inputPaddingLeft + typedTextWidth}px`,
                top: `${inputPaddingTop}px`,
                maxWidth: `calc(100% - ${inputPaddingLeft + typedTextWidth}px)`,
              }}
            >
              {remainder}
            </SuggestionOverlay>
          )}
          {!inputValue && <Placeholder>{intl.formatMessage({ id: 'chatPage.chatInput.placeholder' })}</Placeholder>}
        </StyledInputWrapper>

        <SendButton
          type="button"
          className="btn btn-primary"
          onClick={() => handleSend('button')}
          disabled={!inputValue.trim() || isLoading || isDemoThread}
          aria-label="Send message"
          data-testid="send-button"
        >
          <span className="rioglyph rioglyph-send" />
        </SendButton>
      </InputContainer>

      {error && <ErrorMessage>{intl.formatMessage({ id: error })}</ErrorMessage>}
    </div>
  );
};

export default ChatInput;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--gray-light);
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
  color: var(--color-black);
  caret-color: var(--color-black);
  position: relative;
  z-index: 2;
  font-family: inherit;

  &:focus {
    border-color: var(--brand-primary);
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--gray);
  pointer-events: none;
  background: var(--color-white);
  min-width: 100%;
`;

const MeasureText = styled.span`
  position: absolute;
  top: -9999px;
  left: -9999px;
  white-space: pre;
  font-family: inherit;
  visibility: hidden;
`;

const SuggestionOverlay = styled.span`
  position: absolute;
  pointer-events: none;
  white-space: pre;
  overflow: hidden;
  box-sizing: border-box;
  color: var(--gray);
`;

const SendButton = styled.button<{ disabled: boolean }>`
  width: 46px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .rioglyph-send {
    font-size: 18px;
    position: relative;
    left: 2px;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 4px;
  font-size: 14px;
`;
