import { useIntl } from 'react-intl';

const texts = {
  instructionLink: 'mfa.notification.instruction.link',
  notificationPartOne: 'mfa.notification.partOne',
  notificationPartTwo: 'mfa.notification.partTwo',
  notificationPartThree: 'mfa.notification.partThree',
} as const;

const MfaNotification = () => {
  const intl = useIntl();
  return (
    <div>
      <span>
        <p>{intl.formatMessage({ id: texts.notificationPartOne })}</p>
        <p>
          <a href={intl.formatMessage({ id: texts.instructionLink })} target={'_blank'} rel="noreferrer" className="">
            {intl.formatMessage({ id: texts.notificationPartTwo })}
          </a>
          {intl.formatMessage({ id: texts.notificationPartThree })}
        </p>
        <span className="notification-close">
          <span className="rioglyph rioglyph-remove" />
        </span>
      </span>
    </div>
  );
};

export default MfaNotification;
