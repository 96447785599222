import { KeyboardEventHandler, MouseEvent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useIntl } from 'react-intl';
import {
  resetActiveThread,
  selectActiveThread,
  selectThreadById,
  setActiveThread,
  toggleFavorite,
  updateThreadTitle,
} from '../../../store/thread/threadSlice';
import { getUserProfile } from '../../../configuration/login/loginSlice';
import { threadApi, useDeleteThreadMutation, useUpdateThreadMutation } from '../../../services/threadApi/threadApi';
import { getUserId } from '../../../utils/getUserId';
import useHover from '@rio-cloud/rio-uikit/hooks/useHover';
import ActionIcon from '../../atom/ActionIcon/ActionIcon';
import { useIsDemoThread } from '../../../hooks/useIsDemoThread';
import { RootState } from '../../../store/store';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

const texts = {
  removeFromFavorites: 'chatPage.chatThreadList.threadRemoveFromFavorites.label',
  addToFavorites: 'chatPage.chatThreadList.threadAddToFavorites.label',
  editThreadTitle: 'chatPage.chatThreadList.editThreadTitle.label',
  threadDeletion: 'chatPage.chatThreadList.threadDeletion.label',
  threadDeletionSuccess: 'chatPage.chatThreadList.threadDeletion.success.notification',
  removeFromFavoritesSuccess: 'chatPage.chatThreadList.threadRemoveFromFavorites.success.notification',
  threadDeletionError: 'chatPage.chatThreadList.threadDeletion.error',
  addToFavoritesSuccess: 'chatPage.chatThreadList.threadAddToFavorites.success.notification',
  removingFavoritesError: 'chatPage.chatThreadList.threadToggleFavorites.errorRemoving.notification',
  addingFavoritesError: 'chatPage.chatThreadList.threadToggleFavorites.errorAdding.notification',
  confirmDeletionTitle: 'chatPage.chatThreadList.confirmDeletion.title',
  confirmDeletionContent: 'chatPage.chatThreadList.confirmDeletion.content',
  cancel: 'common.cancel',
  confirm: 'common.confirm',
};

const classes = {
  editIcon: 'rioglyph rioglyph-pencil text-color-primary text-size-18',
  favoriteIcon: 'rioglyph rioglyph-star margin-left-10 text-color-primary text-size-18',
  unfavoriteIcon: 'rioglyph rioglyph-star  margin-left-10 text-color-lighter text-size-18',
  deleteIcon: 'rioglyph rioglyph-trash text-color-primary text-size-18',
  clearIcon: 'rioglyph rioglyph-remove-sign margin-left-5 text-color-primary text-size-18',
  inputTitle: 'width-100pct form-control bg-none text-size-14',
};

type ThreadListItemProps = {
  localId: string;
  threadId: string | null;
  firstMessage: string;
  isFavorite: boolean;
};

const ThreadListItem = ({ localId, threadId, firstMessage, isFavorite }: ThreadListItemProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const activeThreadId = useAppSelector(selectActiveThread)?.id;
  const isDemoThread = useIsDemoThread(localId);
  const thread = useAppSelector((state: RootState) => selectThreadById(state, localId));
  const threadTitle = thread ? thread.title : '';
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [title, setTitle] = useState(threadTitle ?? firstMessage);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const listItemRef = useRef<HTMLLIElement>(null);
  const isHovered = useHover(listItemRef);

  const [deleteThread] = useDeleteThreadMutation();
  const [updateThread, isSuccess] = useUpdateThreadMutation();
  const [fetchMessagesByThreadId] = threadApi.endpoints.fetchMessagesByThreadId.useLazyQuery();

  const handleConfirmDelete = async () => {
    try {
      if (threadId) {
        const response = await deleteThread({ userId, threadId }).unwrap();
        if (response.status === 'success') {
          Notification.success(intl.formatMessage({ id: texts.threadDeletionSuccess }));
          dispatch(resetActiveThread());
        } else {
          Notification.error(intl.formatMessage({ id: texts.threadDeletionError }));
        }
      }
    } catch (error) {
      Notification.error(intl.formatMessage({ id: texts.threadDeletionError }));
    } finally {
      setShowDeleteDialog(false);
    }
  };

  const handleToggleFavorite = async () => {
    try {
      threadId && (await updateThread({ userId, threadId, updateData: { favorite: !isFavorite } }).unwrap());
      if (isSuccess) {
        dispatch(toggleFavorite(localId));
        Notification.success(
          intl.formatMessage({
            id: isFavorite ? texts.removeFromFavoritesSuccess : texts.addToFavoritesSuccess,
          }),
        );
      }
    } catch {
      Notification.error(
        intl.formatMessage({ id: isFavorite ? texts.removingFavoritesError : texts.addingFavoritesError }),
      );
    }
  };

  const handleEditThreadTitle = () => setIsEditing(true);

  const handleOnClick = async () => {
    if (localId !== null) dispatch(setActiveThread(localId));
    if (threadId && !isDemoThread) {
      await fetchMessagesByThreadId({ userId, threadId }).unwrap();
    }
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && isEditing) {
      handleUpdateThreadTitle();
    }
  };

  const handleUpdateThreadTitle = async () => {
    setIsEditing(false);
    if (firstMessage !== title && threadId) {
      await updateThread({ userId, threadId, updateData: { title } });
    }
    if (isSuccess) {
      dispatch(updateThreadTitle({ title }));
    }
  };

  const handleClearTitle = () => {
    setTitle('');
    inputRef.current?.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        setTitle(firstMessage);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [firstMessage]);

  const handleIconClick = (e: MouseEvent<HTMLSpanElement>, handler: () => void) => {
    e.stopPropagation();
    handler();
  };

  const iconProps = [
    {
      onClick: (e: MouseEvent<HTMLSpanElement>) => handleIconClick(e, handleEditThreadTitle),
      iconClass: classes.editIcon,
      tooltipText: texts.editThreadTitle,
    },
    {
      onClick: (e: MouseEvent<HTMLSpanElement>) => handleIconClick(e, () => setShowDeleteDialog(true)),
      iconClass: classes.deleteIcon,
      tooltipText: texts.threadDeletion,
    },
  ];

  useEffect(() => {
    if (threadTitle !== null) setTitle(threadTitle);
  }, [threadTitle]);

  useEffect(() => {
    setIsActive(activeThreadId === localId);
  }, [activeThreadId, localId]);

  return (
    <StyledThreadListItemManager key={`list-item-wrapper-${threadId}`}>
      {!isEditing ? (
        <StyledThreadListItem
          ref={listItemRef}
          onClick={handleOnClick}
          $isactive={isActive}
          data-testid={`chat-thread-${threadId}`}
        >
          <EllipsisText>{title}</EllipsisText>
          {isHovered && (
            <IconContainer>
              {iconProps.map(({ onClick, iconClass, tooltipText }) => (
                <ActionIcon key={tooltipText} onClick={onClick} iconClass={iconClass} tooltipText={tooltipText} />
              ))}
            </IconContainer>
          )}
          <FavoriteIcon
            onClick={(e) => handleIconClick(e, handleToggleFavorite)}
            iconClass={isFavorite ? classes.favoriteIcon : classes.unfavoriteIcon}
            tooltipText={isFavorite ? texts.removeFromFavorites : texts.addToFavorites}
            isFavorite={isFavorite}
          />
          <ConfirmationDialog
            show={showDeleteDialog}
            title={intl.formatMessage({ id: texts.confirmDeletionTitle })}
            content={intl.formatMessage({ id: texts.confirmDeletionContent })}
            onClickConfirm={handleConfirmDelete}
            onClickCancel={() => setShowDeleteDialog(false)}
            cancelButtonText={intl.formatMessage({ id: texts.cancel })}
            confirmButtonText={
              <>
                <span className="rioglyph rioglyph-ok margin-right-5" />
                <span>{intl.formatMessage({ id: texts.confirm })}</span>
              </>
            }
          />
        </StyledThreadListItem>
      ) : (
        <InputContainer>
          <StyledInput
            ref={inputRef}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={handleInputKeyDown}
            autoFocus
            className={classes.inputTitle}
          />
          {title && <ActionIcon onClick={handleClearTitle} iconClass={classes.clearIcon} tooltipText={texts.cancel} />}
        </InputContainer>
      )}
    </StyledThreadListItemManager>
  );
};

export default ThreadListItem;

const EllipsisText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 1.5rem 0 0;
  flex: 1;
`;

const StyledThreadListItem = styled.li<{ $isactive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  ${({ $isactive }) =>
    $isactive &&
    css`
      background-color: var(--color-highlight-lightest);
    `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledThreadListItemManager = styled.div`
  border-radius: 4px;
  padding: 0.1rem;
  transition: background-color 0.3s ease;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
`;

type FavoriteIconProps = {
  isFavorite: boolean;
};

const FavoriteIcon = styled(ActionIcon)<FavoriteIconProps>`
  display: inline-flex;
  color: ${({ isFavorite }) => (isFavorite ? 'var(--color-favorite)' : 'inherit')};
`;
