import { useIntl } from 'react-intl';

const FeedbackInfoSidebar = () => {
  const intl = useIntl();

  const texts = {
    descriptionPartOne: 'feedbackPage.sidebar.description.partOne',
    descriptionPartTwo: 'feedbackPage.sidebar.description.partTwo',
  } as const;

  return (
    <div className="padding-20">
      <p>{intl.formatMessage({ id: texts.descriptionPartOne })}</p>
      <p className="margin-top-20">{intl.formatMessage({ id: texts.descriptionPartTwo })}</p>
    </div>
  );
};

export default FeedbackInfoSidebar;
