import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Step = {
  title: string;
  content: string;
};

type TipsState = {
  showTips: boolean;
  steps: Step[];
  currentStep: number;
};

const initialState: TipsState = {
  showTips: false,
  currentStep: 0,
  steps: [
    {
      title: 'chatPage.sidebar.history.label',
      content: 'chatPage.sidebar.onboardingTip.history.content',
    },
    {
      title: 'chatPage.sidebar.favorites.label',
      content: 'chatPage.sidebar.onboardingTip.favorites.content',
    },
    {
      title: 'chatPage.sidebar.faq.label',
      content: 'chatPage.sidebar.onboardingTip.faq.content',
    },
    {
      title: 'chatPage.sidebar.about.label',
      content: 'chatPage.sidebar.onboardingTip.about.content',
    },
    {
      title: 'chatPage.sidebar.feedback.label',
      content: 'chatPage.sidebar.onboardingTip.feedback.content',
    },
    {
      title: 'chatPage.sidebar.onboardingMode.label',
      content: 'chatPage.sidebar.onboardingTip.onboardingMode.content',
    },
  ],
};

const tipsSlice = createSlice({
  name: 'tips',
  initialState,
  reducers: {
    toggleTips: (state) => {
      state.showTips = !state.showTips;
    },
    setShowTips: (state, action: PayloadAction<boolean>) => {
      state.showTips = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    resetSteps: (state) => {
      state.currentStep = 0;
    },
  },
});

export const { toggleTips, setShowTips, setCurrentStep, resetSteps } = tipsSlice.actions;

export const selectShowTips = (state: { tips: TipsState }) => state.tips.showTips;
export const selectSteps = (state: { tips: TipsState }) => state.tips.steps;
export const selectCurrentStep = (state: { tips: TipsState }) => state.tips.currentStep;

export default tipsSlice.reducer;
