import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { selectIsDemoThread } from '../store/thread/threadSlice';

export const useIsDemoThread = (threadId: string | null) => {
  return useSelector((state: RootState) => {
    if (!threadId) {
      return false;
    }
    return selectIsDemoThread(threadId)(state);
  });
};
