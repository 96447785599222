import { Middleware } from '@reduxjs/toolkit';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';

const gatewayTimeoutMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (action.payload?.status === 504) {
    Notification.error('The server took too long to respond. Please try again later.', 'Error');
  }
  return next(action);
};

export default gatewayTimeoutMiddleware;
