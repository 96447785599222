import { Fragment, useRef } from 'react';
import HeroSection from '../HeroSection/HeroSection';
import TailoredFeaturesSection from '../TailoredFeaturesSection/TailoredFeaturesSection';
import GetStartedSection from '../GetStartedSection/GetStartedSection';

const Welcome = () => {
  const componentTopRef = useRef<HTMLDivElement>(null);

  const scrollFunction = () => {
    if (componentTopRef.current) {
      componentTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Fragment>
      <HeroSection scrollFunction={scrollFunction} />
      <TailoredFeaturesSection ref={componentTopRef} />
      <GetStartedSection />
    </Fragment>
  );
};

export default Welcome;
