import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const extractTableDataFromMarkdown = (markdownContent: string) => {
  const tableData: string[][] = [];

  const lines = markdownContent.split('\n');

  lines.forEach((line) => {
    if (line.includes('|')) {
      const row = line
        .split('|')
        .map((cell) => cell.trim().replace(/\*\*/g, ''))
        .map((cell) => cell.trim())
        .filter(Boolean);

      if (row.some((cell) => !/^[-]+$/.test(cell))) {
        tableData.push(row);
      }
    }
  });

  return tableData;
};

export const exportMarkdownTableToPDF = (markdownContent: string, fileName: string) => {
  const tableData = extractTableDataFromMarkdown(markdownContent);

  if (tableData.length > 0) {
    const headers = tableData[0];
    const rows = tableData.slice(1);

    const doc = new jsPDF({
      orientation: 'landscape', // Orientacja pozioma
      unit: 'mm',
      format: 'a4',
    });

    const timestamp = new Date().toLocaleString();
    doc.setFontSize(8); // Mniejsza czcionka dla tekstu poza tabelą
    doc.text(`Generated on: ${timestamp}`, 14, 10);

    // Oblicz szerokość strony w pikselach
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10; // Marginesy po obu stronach
    const availableWidth = pageWidth - 2 * margin; // Dostępna szerokość dla tabeli

    // Oblicz szerokość kolumn
    const columnCount = headers.length;
    const columnWidth = availableWidth / columnCount; // Równa szerokość dla każdej kolumny

    autoTable(doc, {
      head: [headers],
      body: rows,
      startY: 20, // Początek tabeli 20 mm od góry
      headStyles: {
        fillColor: [48, 180, 192],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
        fontSize: 7, // Bardzo mała czcionka dla nagłówków
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        fontSize: 7, // Bardzo mała czcionka dla treści
      },
      theme: 'striped',
      styles: {
        overflow: 'linebreak', // Zawijanie tekstu w komórkach
        cellWidth: columnWidth, // Równa szerokość dla każdej kolumny
        fontSize: 7, // Bardzo mała czcionka
      },
      margin: { top: 20, right: margin, bottom: 10, left: margin }, // Marginesy
      tableWidth: availableWidth, // Szerokość tabeli dostosowana do dostępnej szerokości
      pageBreak: 'auto', // Automatyczny podział na strony
      showHead: 'everyPage', // Powtarzaj nagłówki na każdej stronie
    });

    doc.save(`${fileName}.pdf`);
  }
};