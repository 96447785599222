import { Middleware } from '@reduxjs/toolkit';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';

const sessionExpiryMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (action.payload?.status === 401) {
    Notification.error('Your session has expired, please refresh the page.', 'Error');
  }
  return next(action);
};

export default sessionExpiryMiddleware;
