import { Feedback } from '../../store/feedback/feedbackSlice';
import { selectMessageById } from '../../store/chat/chat.slice';
import { FeedbackRequest } from '../../services/feedbackApi/feedback';
import { reduxStore } from '../../store/store';

export const mapFeedbackToRequest = (feedback: Feedback): FeedbackRequest => {
  const state = reduxStore.getState();

  const lastQuestionContent = feedback.lastQuestionId
    ? selectMessageById(feedback.lastQuestionId)(state)?.content
    : null;

  const lastAnswerContent = feedback.lastAnswerId ? selectMessageById(feedback.lastAnswerId)(state)?.content : null;

  let rating: 'negative' | 'neutral' | 'positive' | undefined;

  if (feedback.rate !== undefined) {
    if (feedback.rate === 1 || feedback.rate === 2) {
      rating = 'negative';
    } else if (feedback.rate === 3) {
      rating = 'neutral';
    } else if (feedback.rate === 4 || feedback.rate === 5) {
      rating = 'positive';
    }
  }

  const request: FeedbackRequest = {
    detailed_answer: feedback.detail,
    user_id: feedback.userId,
  };

  if (lastQuestionContent) {
    request.last_question = lastQuestionContent;
  }

  if (lastAnswerContent) {
    request.last_answer = lastAnswerContent;
  }

  if (rating) {
    request.rating = rating;
  }

  return request;
};
