import { createEntityAdapter, createSelector, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { threadApi } from '../../services/threadApi/threadApi';
import { Response } from '../../utils/types';
import { chatApi } from '../../services/chatApi/chatApi';
import { categorizeThreadsByDate } from '../../utils/categorizeThreadsByDate/categorizeThreadsByDate';
import { setCurrentLayout } from '../layout/layoutSlice';

export type ChatThread = {
  id: string;
  threadId: string | null;
  title: string;
  timestamp: string;
  isFavorite: boolean;
};

type ThreadsState = {
  activeThreadId: string | null;
  loadingThreadIds: (string | null)[];
  isFetchingHistory: boolean;
};

const threadsAdapter = createEntityAdapter<ChatThread>({
  sortComparer: (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
});

const createTodayThread = (): ChatThread => {
  const today = new Date().toISOString();
  return {
    id: nanoid(),
    threadId: null,
    title: '',
    timestamp: today,
    isFavorite: false,
  };
};

const initialState: ThreadsState & { threads: ReturnType<typeof threadsAdapter.getInitialState> } = {
  threads: threadsAdapter.getInitialState(),
  activeThreadId: null,
  loadingThreadIds: [],
  isFetchingHistory: false,
};

const threadSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    resetActiveThread: (state) => {
      state.activeThreadId = null;
    },
    addNewThread: (state) => {
      const newThread = createTodayThread();
      threadsAdapter.addOne(state.threads, newThread);
      state.activeThreadId = newThread.id;
    },
    setActiveThread: (state, action: PayloadAction<string>) => {
      state.activeThreadId = action.payload;
    },
    updateThreadTitle: (state, action: PayloadAction<{ title: string }>) => {
      const { title } = action.payload;
      const threadToUpdate = Object.values(state.threads.entities).find((thread) => thread?.threadId === null);

      if (threadToUpdate) {
        threadsAdapter.updateOne(state.threads, {
          id: threadToUpdate.id,
          changes: { title },
        });

        if (state.activeThreadId === null) {
          state.activeThreadId = threadToUpdate.id;
        }
      }
    },

    toggleFavorite: (state, action: PayloadAction<string>) => {
      const thread = state.threads.entities[action.payload];
      if (thread) {
        threadsAdapter.updateOne(state.threads, {
          id: action.payload,
          changes: { isFavorite: !thread.isFavorite },
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentLayout, (state, action) => {
      if (action.payload === 'onboardingMode') {
        state.activeThreadId = null;
      }
    });
    builder.addMatcher(
      threadApi.endpoints.fetchThreads.matchFulfilled,
      (state, action: PayloadAction<Response<ChatThread[]>>) => {
        state.isFetchingHistory = false;
        if (action.payload.data) {
          const threadsWithLocalId = action.payload.data.map((thread) => ({
            ...thread,
            id: nanoid(),
          }));
          threadsAdapter.upsertMany(state.threads, threadsWithLocalId);
        }
      },
    );

    builder.addMatcher(chatApi.endpoints.sendMessage.matchPending, (state, action) => {
      state.loadingThreadIds.push(state.activeThreadId);
    });

    builder.addMatcher(chatApi.endpoints.sendMessage.matchRejected, (state, action) => {
      state.loadingThreadIds = state.loadingThreadIds.filter((id) => id !== state.activeThreadId);
    });

    builder.addMatcher(chatApi.endpoints.sendMessage.matchFulfilled, (state, action) => {
      const responseThreadId = action.payload.threadId;
      if (responseThreadId) {
        const threadToUpdate = Object.values(state.threads.entities).find((thread) => thread.threadId === null);

        if (threadToUpdate) {
          threadsAdapter.updateOne(state.threads, {
            id: threadToUpdate.id,
            changes: { threadId: responseThreadId },
          });
          if (state.activeThreadId === null) state.activeThreadId = threadToUpdate.id;
        }
      }
      state.loadingThreadIds = state.loadingThreadIds.filter((id) => id !== state.activeThreadId);
    });

    builder.addMatcher(threadApi.endpoints.fetchThreads.matchPending, (state) => {
      state.isFetchingHistory = true;
    });

    builder.addMatcher(threadApi.endpoints.fetchThreads.matchRejected, (state) => {
      state.isFetchingHistory = false;
    });

    builder.addMatcher(threadApi.endpoints.deleteThread.matchFulfilled, (state, action) => {
      const deletedThreadId = action.meta.arg.originalArgs.threadId;
      const threadToDelete = Object.values(state.threads.entities).find(
        (thread) => thread.threadId === deletedThreadId,
      );
      if (threadToDelete) threadsAdapter.removeOne(state.threads, threadToDelete.id);
      if (state.activeThreadId === deletedThreadId) state.activeThreadId = null;
    });
  },
});

export const { addNewThread, resetActiveThread, setActiveThread, updateThreadTitle, toggleFavorite } =
  threadSlice.actions;

export default threadSlice.reducer;

const threadSelectors = threadsAdapter.getSelectors<RootState>((state) => state.thread.threads);

export const selectAllThreads = threadSelectors.selectAll;
export const selectThreadById = threadSelectors.selectById;
export const selectThreadIds = threadSelectors.selectIds;
export const selectActiveThread = createSelector(
  [(state: RootState) => state.thread.activeThreadId, threadSelectors.selectAll],
  (activeThreadId, allThreads) => {
    return allThreads.find((thread) => thread.id === activeThreadId) || null;
  },
);
export const selectFavoriteThreads = createSelector(selectAllThreads, (threads) =>
  threads.filter((thread) => thread.isFavorite),
);
export const selectIsThreadLoading = (id: string | null) => (state: RootState) =>
  state.thread.loadingThreadIds.includes(id);

export const selectIsHistoryOfThreadsFetching = (state: RootState) => state.thread.isFetchingHistory;
export const selectIsDemoThread = (threadId: string) => (state: RootState) => {
  const thread = threadSelectors.selectById(state, threadId);
  return thread ? thread.title.startsWith('DEMO') : false;
};
export const selectCategorizedThreads = createSelector(selectAllThreads, (threads) => categorizeThreadsByDate(threads));
