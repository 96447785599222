import { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Checkbox from '@rio-cloud/rio-uikit/components/checkbox/Checkbox';
import { useIntl } from 'react-intl';
import SafetyNoticeContent from '../../molecule/SafetyNoticeContent/SafetyNoticeContent';

type SafetyNoticeProps = {
  show: boolean;
  checked: boolean;
  setShow: (value: boolean) => void;
  onClose: () => void;
  handleOnChange: () => void;
};

const fleetAssistantApplication = window.location.href.includes('about')
  ? window.location.href
  : `${window.location.href}about`;

const texts = {
  confirmation: 'safetyNotice.confirmation',
  checkboxOnePartOne: 'safetyNotice.confirmation.checkboxOne.partOne',
  checkboxOneLink: 'safetyNotice.confirmation.checkboxOne.link',
  checkboxOnePartTwo: 'safetyNotice.confirmation.checkboxOne.partTwo',
  checkboxTwoPartOne: 'safetyNotice.confirmation.checkboxTwo.partOne',
  checkboxTwoPartTwo: 'safetyNotice.confirmation.checkboxTwo.partTwo',
  checkboxTwoPartThree: 'safetyNotice.confirmation.checkboxTwo.partThree',
  checkboxThreePartOne: 'safetyNotice.confirmation.checkboxThree.partOne',
  checkboxThreeLink: 'safetyNotice.confirmation.checkboxThree.link',
  checkboxThreePartTwo: 'safetyNotice.confirmation.checkboxThree.partTwo',
  notificationSuccessTitle: 'safetyNotice.notification.success.title',
  notificationSuccessContent: 'safetyNotice.notification.success.content',
} as const;

const SafetyNotice = ({ show, onClose, setShow }: SafetyNoticeProps) => {
  const intl = useIntl();

  const [checkboxOne, setCheckboxOne] = useState(false);
  const [checkboxTwo, setCheckboxTwo] = useState(false);
  const [checkboxThree, setCheckboxThree] = useState(false);

  const allChecked = checkboxOne && checkboxTwo && checkboxThree;

  const handleSubmit = () => {
    localStorage.setItem('termsAccepted', 'true');
    localStorage.setItem('termsAcceptedAt', Date.now().toString());
    setShow(false);
  };

  const footer = (
    <div>
      <button type="button" className="btn btn-primary btn-outline" onClick={handleSubmit} disabled={!allChecked}>
        <span className="rioglyph rioglyph-ok" aria-hidden="true" />
        <span className="text-capitalize">
          {intl.formatMessage({ id: 'getStartedPage.expanderPanelWarning.buttonConfirm' })}
        </span>
      </button>
    </div>
  );

  const body = (
    <div>
      <SafetyNoticeContent />
      <p className="margin-top-25">{intl.formatMessage({ id: texts.confirmation })}</p>
      <div className="padding-x-10">
        <fieldset className="col-sm-4">
          <p>
            <Checkbox checked={checkboxOne} onChange={() => setCheckboxOne(!checkboxOne)}>
              <span>{intl.formatMessage({ id: texts.checkboxOnePartOne })}</span>
              <strong>{intl.formatMessage({ id: texts.checkboxOneLink })}</strong>
              <span>{intl.formatMessage({ id: texts.checkboxOnePartTwo })}</span>
              <span className="text-color-rating-1">*</span>
            </Checkbox>
          </p>
          <p>
            <Checkbox checked={checkboxTwo} onChange={() => setCheckboxTwo(!checkboxTwo)}>
              <span>{intl.formatMessage({ id: texts.checkboxTwoPartOne })}</span>
              <strong>{intl.formatMessage({ id: texts.checkboxTwoPartTwo })}</strong>
              <span>{intl.formatMessage({ id: texts.checkboxTwoPartThree })}</span>
              <span className="text-color-rating-1">*</span>
            </Checkbox>
          </p>
          <p>
            <Checkbox checked={checkboxThree} onChange={() => setCheckboxThree(!checkboxThree)}>
              <span>{intl.formatMessage({ id: texts.checkboxThreePartOne })}</span>
              <strong>{intl.formatMessage({ id: texts.checkboxThreeLink })}</strong>
              <span>{intl.formatMessage({ id: texts.checkboxThreePartTwo })}</span>
              <span className="text-color-rating-1">*</span>
            </Checkbox>
          </p>
        </fieldset>
      </div>
    </div>
  );

  return (
    <Dialog
      show={show}
      title={intl.formatMessage({ id: 'getStartedPage.expanderPanelWarning.title' })}
      footer={footer}
      body={body}
      showCloseButton={false}
      disableEsc={true}
      bsSize="md"
      useOverflow
      onClose={onClose}
    />
  );
};

export default SafetyNotice;
