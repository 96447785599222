import { useIntl } from 'react-intl';

const texts = {
  welcomeText: 'safetyNotice.welcomeText',
  privacyTitle: 'safetyNotice.privacyTitle',
  privacyContentPartOne: 'safetyNotice.privacyContent.partOne',
  privacyContentPartOneLink: 'safetyNotice.privacyContent.partOne.link',
  privacyContentPartTwo: 'safetyNotice.privacyContent.partTwo',
  privacyContentPartTwoLink: 'safetyNotice.privacyContent.partTwo.link',
  privacyContentPartThree: 'safetyNotice.privacyContent.partThree',
  serviceGTC: 'safetyNotice.applicability.serviceGTC.link',
  accuracyTitle: 'safetyNotice.accuracyTitle',
  accuracyContent: 'safetyNotice.accuracyContent',
  applicabilityTitle: 'safetyNotice.applicabilityTitle',
  applicabilityContentPartOne: 'safetyNotice.applicabilityContent.partOne',
  applicabilityPartOneLink: 'safetyNotice.applicability.partOne.link',
  applicabilityPartTwo: 'safetyNotice.applicability.partTwo',
  termsOfServiceLink: 'safetyNotice.privacyContent.termsOfService.link',
} as const;

const fleetAssistantApplication = window.location.href.includes('about')
  ? window.location.href
  : `${window.location.href}about`;

const SafetyNoticeContent = () => {
  const intl = useIntl();
  return (
    <div>
      <p>{intl.formatMessage({ id: texts.welcomeText })}</p>
      <ol>
        <li>
          <span className="text-bold">{intl.formatMessage({ id: texts.privacyTitle })}</span>
          <span>{intl.formatMessage({ id: texts.privacyContentPartOne })}</span>
          <a href={fleetAssistantApplication} target={'_blank'} rel="noreferrer">
            {intl.formatMessage({ id: texts.privacyContentPartOneLink })}
          </a>
          <span>{intl.formatMessage({ id: texts.privacyContentPartTwo })}</span>
          <a href={intl.formatMessage({ id: texts.termsOfServiceLink })} target={'_blank'} rel="noreferrer">
            {intl.formatMessage({ id: texts.privacyContentPartTwoLink })}
          </a>
          <span>{intl.formatMessage({ id: texts.privacyContentPartThree })}</span>
        </li>
        <li>
          <span className="text-bold">{intl.formatMessage({ id: texts.accuracyTitle })}</span>
          <span>{intl.formatMessage({ id: texts.accuracyContent })}</span>
        </li>
        <li>
          <span className="text-bold">{intl.formatMessage({ id: texts.applicabilityTitle })}</span>
          <span>{intl.formatMessage({ id: texts.applicabilityContentPartOne })}</span>
          <a href={intl.formatMessage({ id: texts.serviceGTC })} target={'_blank'} rel="noreferrer">
            {intl.formatMessage({ id: texts.applicabilityPartOneLink })}
          </a>
          <span>{intl.formatMessage({ id: texts.applicabilityPartTwo })}</span>
        </li>
      </ol>
    </div>
  );
};

export default SafetyNoticeContent;
